import React from 'react';
import IntakeForm from '../../components/IntakeForm';
import './index.scss';
import Layout from "../../components/Layout";

const Contact = () => (
    <Layout>
      <div className="bg-light">
        <div className="jumbotron text-center bg-light">
          <div className="container">
            <h1 className="display-4 font-weight-bold">Lets get things <span className="highlight">going</span></h1>
            <p className="text-secondary h5 font-italic intake-form-subtext">
              Use this form to tell us a little about yourself and your project.
              We will follow up within the same day and reach out to you about what
              you'd like to accomplish.
            </p>
          </div>
        </div>
        <div className="container mb-5">
          <IntakeForm />
        </div>
      </div>
    </Layout>
);

export default Contact;
