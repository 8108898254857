import React from 'react';
import './index.scss';

const IntakeForm = () => (
  <form name="intake" action="/success" method="POST" data-netlify="true">
    <div className="row">
      <div className="col-md-6">
        <div className="form-group">
          <label for="name">Your Name</label>
          <input type="text" className="form-control form-control-lg" id="name" />
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-group">
          <label for="email">Email</label>
          <input type="email" className="form-control form-control-lg" id="email" />
        </div>
      </div>
    </div>
    <div className="form-group">
      <label for="company_name">Company's Name</label>
      <input type="text" className="form-control form-control-lg" id="company_name" placeholder="Browntree Labs" />
      <secondary className="text-muted">The name of the company we will be working with</secondary>
    </div>
    <div className="row">
      <div className="col-md-6">
        <div className="form-group">
          <label for="deadline">Deadline</label>
          <input type="date" className="form-control form-control-lg" id="deadline" />
          <secondary className="text-muted">when would you like work completed?</secondary>
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-group">
          <label for="budget">Budget</label>
          <input type="number" className="form-control form-control-lg" id="budget" />
          <secondary className="text-muted">In US Dollars</secondary>
        </div>
      </div>
    </div>

    <div className="form-group">
      <label for="description">Project Description</label>
      <textarea rows="10" id="description" className="form-control form-control-lg" placeholder="I need to create a new paradigm-shifting application! It will change the way we take photos of cats forever..." />
    </div>

    <button type="submit" className="btn btn-block btn-lg btn-orange text-light">
      Submit
    </button>
  </form>
);

export default IntakeForm;
